<template>

    <TablePage ref="TablePage" main-url="/nsi-reference" @tableItemsClick="tableItemsClick">

        <template #tableColumns="TableProps">

            <Column :sortable="true" field="id" filter-field="id">
                <template #header>
                    <SearchInput v-model="TableProps.filters['id']" label="Идентификатор"/>
                </template>
            </Column>

            <Column :sortable="true" field="name" filter-field="name">
                <template #header>
                    <SearchInput v-model="TableProps.filters['name']" label="Наименование"/>
                </template>
            </Column>

            <Column :sortable="true" field="meta_version" filter-field="meta_version">
                <template #header>
                    <SearchInput v-model="TableProps.filters['meta_version']" label="Версия схемы"/>
                </template>
            </Column>

            <Column :sortable="true" field="data_version" filter-field="data_version">
                <template #header>
                    <SearchInput v-model="TableProps.filters['data_version']" label="Версия данных"/>
                </template>
            </Column>

        </template>

    </TablePage>

    <NsiCard ref="NsiCard"/>


</template>

<script>
import {DataFilters, Helper, SearchInput, TablePage} from "@xnpmpackages/xcomponents";
import NsiCard from "@/components/app/NsiReference/NsiCard";

export default {
    name: "NsiReference",
    components: {NsiCard, SearchInput, TablePage},
    mixins: [DataFilters, Helper],
    data() {
        return {}
    },
    methods: {
        tableItemsClick($event) {
            this.$refs.NsiCard.show($event.data);
        }
    },
    mounted() {
        //this.$refs.TablePage.getTableRows(true);
    }
}
</script>

<style scoped>

</style>