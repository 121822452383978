<template>

    <div>

        <XModal ref="XModal" :show-overlay="true">

            <template #header>
                Просмотр карточки абонента
            </template>
            <template #default>

                <div :style="{minWidth: '30vw', maxWidth: '80vw'}">

                    <table class="p-datatable-table" style="width: 100%;">
                        <tbody>

                        <tr>
                            <td>Идентификатор</td>
                            <td>{{ itemData.id }}</td>
                        </tr>
                        <tr>
                            <td>Наименование</td>
                            <td>{{ itemData.name }}</td>
                        </tr>
                        <tr>
                            <td>Версия схемы</td>
                            <td>{{ itemData.meta_version }}</td>
                        </tr>
                        <tr>
                            <td>Версия данных</td>
                            <td>{{ itemData.data_version }}</td>
                        </tr>


                        </tbody>
                    </table>

                    <h5>Содержимое справочника</h5>
                    <pre style="color: var(--gray-400)">{{ JSON.stringify(itemData.content, null, 2) }}</pre>

                </div>


            </template>


        </XModal>

    </div>

</template>

<script>
import {XModal} from "@xnpmpackages/xcomponents";

export default {
    name: "NsiCard",
    components: {XModal},
    data() {
        return {
            itemData: null
        }
    },
    methods: {
        show($itemData) {
            this.itemData = $itemData;
            this.$refs.XModal.show();
        },
    },
}
</script>

<style scoped>

.p-datatable-table {
    border-collapse: collapse;
}

.p-datatable-table tbody > tr > td, .p-datatable-table thead > tr > td {
    padding: 5px 20px;
}

.p-datatable-table > tbody > tr:nth-child(even) {
    /*background-color: #0a3622;*/
    background-color: var(--surface-ground);
}
</style>